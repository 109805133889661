<template>
  <div class="address-page">
    <van-radio-group
      v-model="defaultAddress"
      checked-color="var(--van-card-price-color)"
      icon-size="16"
      @change="setDefault"
      v-if="list.length > 0"
    >
      <ul class="address-list">
        <template v-for="(item, index) in list" :key="index">
          <li class="item">
            <div class="info" @click="selectAddress(index)">
              <span class="detail" style="font-size:18px;">合同名稱:{{item.contractName}}</span>
              <span class="area">姓名:{{item.userName}}</span>
              <span class="phone">身份證號:{{ item.idCard}}</span>
			  <!-- <span class="detail" style="margin-top:10px;text-decoration: underline;fotn-size:12px" @click="onCopy(item.contractUrl)">複製合同鏈接</span> -->
            </div>
        
          </li>
        </template>
      </ul>
    </van-radio-group>


  </div>

</template>

<script>
import {
  showToast,
  showConfirmDialog,
  showLoadingToast,
  closeToast,
} from "vant";
import { getCookie } from "../util";


export default {
  data() {
    return {
      list: [],
      defaultAddress: 1,
      fromorder: 0,
      agentInfo: {},
      addrid: -1,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.address;
    this.GOODSLANG = this.LANG.pages.goods;
    this.CARTLANG = this.LANG.pages.cart;
    this.MYLANG = this.LANG.pages.my;
    this.SYSLANG = this.LANG.system;

    if (this.$route.query.fromorder) {
      this.fromorder = this.$route.query.fromorder;
    }

    this.getMemberInfo();
	this.init();
  },
  methods: {
	  
	  
	onCopy(content) {
	  let oInput = document.createElement("input");
	  oInput.value = content;
	  document.body.appendChild(oInput);
	  oInput.select(); // 选择对象;
	  console.log(oInput.value);
	  document.execCommand("Copy"); // 执行浏览器复制命令
	  oInput.remove();
	  showToast(this.SYSLANG.copysuccess);
	},  
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .get(this.actions.memberInfo)
        .then(({ data }) => {
          closeToast();
          let { code, msg } = data;
          if (code == "200") {
            this.agentInfo = data.data;
            //this.init();
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.GOODSLANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          console.log("views.Address.getMemberInfo.error", error);
          closeToast();
          //this.getMemberInfo();
        });
    },
    init() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .get(this.actions.getContractList,{params:{page:1,pageSize:15}})
        .then((res) => {
          closeToast();
          let { code, message} = res.data;
          if (code == 200) {
			    console.log('list',res.data.data.list)
				for (let i = 0; i < res.data.data.list.length; i++) {
				  if (res.data.data.list[i].isDefault == 1) {
						this.defaultAddress = res.data.data.list[i].addressId;
						break;
				  }
				}
				this.list = res.data.data.list;
				
          } else {
            showToast({
              message: this.SYSLANG[message],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          console.log("views.Address.init.error", error);
          closeToast();
          //this.init();
        });
    },
    setDefault(val) {
      console.log(val);
	  let postObj={}
	  this.list.map((item)=>{
		   if(item.addressId==val){
			   postObj=item
		   }
	  })
	  postObj.isDefault=1
      // 设置默认地址
      this.axios
        .post(this.actions.addressEdit,postObj)
        .then(({ data }) => {
          let { code, message } = data;
          console.log(code, message);
          if (code ==200) {
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].ID == this.defaultAddress) {
                this.list[i].IsDefault = 1;
              } else {
                this.list[i].IsDefault = 0;
              }
            }
          } else {
            showToast(
              code != 200 ? this.SYSLANG[message] : this.PAGELANG[message]
            );
          }
        })
        .catch((error) => {
          console.log("views.Address.setDefault.error", error);
          closeToast();
          this.setDefault(val);
        });
    },
    onDelete(addrid) {

      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.deleteaddrss,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.SYSLANG.正在删除,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .get(this.actions.addressDel,{params:{addressId:addrid}})
            .then((res) => {
              closeToast();
              let { code, message } = res.data;
			  if(code==200){
				  showToast({
					   message:message,
					   onClose:()=>{
						   this.init();
					   },
					   orbidClick: true,
				  })
			  }else{
				 showToast({
				 		message:message,
				 		orbidClick: true,
				 }) 
			  }
            })
            .catch((error) => {
              console.log("views.Address.onDelete.error", error);
              closeToast();
            });
        })
        .catch(() => {});
    },
    selectAddress(index) {
      if (this.fromorder == 1) {
			this.pagedata.addrinfo = this.list[index];
			this.$router.back();
      }
    },
    editAddress(addrid = 0) {
      console.log(addrid);
      this.addrid = addrid;
    },
  },
};
</script>

<style src="../assets/css/address.css" scoped></style>
<style src="../assets/css/addresslist.css" scoped></style>